.radio__group {
    display: grid;
    gap: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    margin-bottom: 16px;

}

.label {
    font-size: 16px;
    font-weight: 500;
}

.option {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}