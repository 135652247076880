/* Contenedor general del menú lateral */
.menu__container {
    width: 250px;
    height: 100vh;
    background-color: #1a1d21;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s;

    padding: 8px;
    position: relative;
    z-index: 99999;
}

.menu__collapsed {
    width: 70px ;
    height: 100vh;
    background-color: #1a1d21;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s;
    padding: 8px;
    position: relative;
    z-index: 99999;
}

.menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 40px;
    width: 90%;
}

.menu__logo {
    height: 40px;
    cursor: pointer;
}

.menu__hideButton {
    background: none;
    border: none;
    color: #7a7c7e;
    fill: #7a7c7e;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
    cursor: pointer;
    width: 100%;
}

.menu__content {
    padding: 0 20px;
    margin-top: 10px;
}

.menu__title {
    margin-bottom: 10px;
    color: #cccccc;
}

.menu__list {
    list-style: none;
    padding: 0;
}

.menu__listItem {
    margin: 15px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.menu__listItem a {
    color: white;
    text-decoration: none;
}

.menu__listItem svg {
    margin-right: 10px;
}

.arrow_icon {
    fill: #7a7c7e;
    width: 24px;
    height: 24px;
}

.icon {
    width: 20px; /* Ajusta el tamaño según sea necesario */
    height: 20px;
    margin-right: 8px; /* Espacio entre la imagen y el texto */
}

.menu__collapsed .menu__listItem svg {
    margin-right: 0;
}

.menu__collapsed .menu__listItem {
    justify-content: center;
}

.menu__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    border-top: 1px solid #3a3d41;

    margin: 0 auto;
    width: 100%;
}

.menu__userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.menu__avatar {
    background-color: #3578e5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    margin-right: 8px;
}

.menu__collapsed .menu__avatar {
    margin: 0;
}

.menu__userDetails {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    transition: all 0.3s ease;
}

.menu__userName {
    font-weight: bold;
}

.menu__userEmail {
    font-size: 12px;
    color: #7a7c7e;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu__logoutButton {
    background: none;
    border: none;
    color: #7a7c7e;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.menu__logoutButton svg {
    margin-right: 10px;
    justify-content: center;
}

.menu__header,
.menu__footer,
.menu__content {
    transition: opacity 0.3s;
}

.menu__collapsed .menu__footer {
    justify-content: center;
    align-items: center;
}

.menu__collapsed .menu__hideButton {
    justify-content: center;
    align-items: center;
}

.menu__collapsed .menu__title,
.menu__collapsed .menu__userName,
.menu__collapsed .menu__userEmail {
    display: none;
}

@media (min-width: 768px) {
    .menu__content {
        padding: 0;
    }
}