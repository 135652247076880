.report__section {
    padding: 20px 15vw;
    background-color: #fff;
    
}

.breadcrumb {
    font-size: 14px;
    color: #666;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    color: black;
}

.date {
    font-size: 16px;
    color: #999;
    margin-top: 5px;
}

.description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
}

.graph__placeholder {
    height: 200px; /* Placeholder height */
    background-color: #e0e0e0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #666;
}

.subheading {
    font-size: 20px;
    margin-top: 30px;
    font-weight: bold;
}

.career__list {
    margin-top: 20px;
}


