/* App.module.css */
.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0; /* Adjust as needed */
    animation: 
      typing 2s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }

  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    background-color: #065f5b;
    color: white;
    padding: 40px 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 65vh;

    position: relative;
}

.welcome__message {
    text-align: left;
    margin-bottom: 20px;
    width: 70%;

    z-index: 2;
}

.welcome__message h1 {
    font-size: 48px;
}

.welcome__message p {
    font-size: 20px;
}

.input {
    z-index: 2;
}

.input__box {
    padding: 10px;
    width: 50%;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    outline: none;

    z-index: 2;
}

.news__section {
    background-color: #00c06f;
    color: black;
    padding: 24px;
    flex-grow: 1;
    max-height: 35%;
}

.news__section h2 {
    font-size: 28px;
    
}

.news__content {
    display: flex;
    justify-content: space-between;
}

.news__item {
    width: 45%;
    height: 60%;
    padding: 5px;
}

.news__item h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.news__item p {
    font-size: 16px;
}

@media (min-width: 768px) {
    .news__section {
        padding: 40px 120px;
    }
}