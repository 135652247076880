.career__item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-top: 15px;
}

.career__title {
    font-size: 18px;
    font-weight: bold;
}

.career__details {
    margin-top: 10px;
}

.view__more {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.view__more:hover {
    text-decoration: underline;
}