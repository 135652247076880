.decorative__square {
    position: absolute;
    width: 100px;
    height: 100px;

    z-index: 1;
}

.hide {
    display: none;
}