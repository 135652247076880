.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    background-color: #065f5b;
    color: white;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 65dvh;

    position: relative;
}

.compress {
    min-height: 30dvh;
    transition: all 1s ease;
}

.breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 14px;
    margin: 0 0 16px;
    gap: 4px;

    position: absolute;
    top: 24px;

    text-decoration: none;
    cursor: pointer;
}

.header h1, 
.header p {
    text-align: left;
    margin-bottom: 20px;
    width: 100%;

    z-index: 2;
}

.header h1 {
    font-size: 48px;
}

.header p {
    font-size: 18px;
    margin: 0;
}

.compress h1 {
    font-size: 24px;
    margin: 0 0 8px;
    transition: all 1s ease;
}

.compress p {
    font-size: 14px;
    margin: 0;
    transition: all 1s ease;
}

.exam {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: black;
    padding: 24px;
}

.button {
    width: fit-content;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 24px;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db; 
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (min-width: 768px) {
    .header {
        padding: 40px 120px;
    }

    .header h1, 
    .header p {
        width: 70%;
    }

    .exam {
        padding: 40px 120px;
    }
}