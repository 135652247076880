.root {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.root:focus {
    outline: none;
}

.root:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.root:active {
    background-color: #004286;
    border-color: #004286;
}

.root:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: not-allowed;
}