.radio__group {
    display: grid;
    gap: 8px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.label {
    font-size: 16px;
    font-weight: 500;
}

.option {
    display: flex;
    
    gap: 8px;
}

.button__container {
    display: flex;
    justify-content: space-between;
}

.button {
    margin: 24px 0 0;
}