.input {
    padding: 10px;
    width: 300px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    outline: none;
}

.input_invalid {
    padding: 10px;
    width: 300px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid red;
    background-color: transparent;
    color: white;
    outline: none;
}

.input::placeholder {
    color: #b3b3b3;
}

.button {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    background-color: #00c181; /* Verde claro */
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    transition: background-color 0.3s ease;
}