/* LoginScreen.css */
.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto 20px; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0; /* Adjust as needed */
    animation: 
      typing 2s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }

  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }

.login__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    padding: 0 20px;
    background-color: #025f4d; /* Color verde de fondo */
}

.title {
    position: relative;
    font-size: 48px;
    color: white;
    margin-bottom: 20px;
    z-index: 999;
    width: fit-content;
}

.center {
    text-align: center;
    margin: 0 auto 20px;
}

.login__input {
    padding: 10px;
    width: 300px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    outline: none;
}

.login__input_invalid {
    padding: 10px;
    width: 300px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid red;
    background-color: transparent;
    color: white;
    outline: none;
}

.login__input::placeholder {
    color: #b3b3b3;
}

.login__button {
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    background-color: #00c181; /* Verde claro */
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    transition: background-color 0.3s ease;

    z-index: 999;
}

.login__button:hover {
    background-color: #028760; /* Cambia el color al pasar el ratón */
}

/* Cuadros decorativos */
.decorative__square {
    position: absolute;
    background-color: #00c181;
    width: 100px;
    height: 100px;
}

.top__right {
    top: 150px;
    right: 100px;
    background-color: #26d07c;
}

.top__right__second {
    top: 250px;
    right: 0px;
    background-color: #007864;
}

.bottom__left {
    bottom: 0px;
    left: 0px;
    background-color: #00a499;
    z-index: 999;
}

.checkbox__group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    z-index: 999;

    gap: 16px;
}

.option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;

    width: calc(50% - 32px);
}

.option img {
    width: 100%;
}

.option span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: white;
}

@media (min-width: 768px) {
    .login__container {
        padding: 0 96px
    }

    .option {
        width: 20%;
    }
}