.container {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.checkbox__group {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 20px;
}

.option {
    display: flex;

    gap: 8px;
}

@media (min-width: 768px) {

    .checkbox__group {
        grid-template-columns: repeat(4, 1fr);
    }
}